<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <h3>Pipeline - Permitting</h3>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-combobox
                    v-model="sortSelected"
                    :items="listSortOptions"
                    label="Sort By"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    @change="sortRegisters"
                    dense
                ></v-combobox>

                <v-tooltip bottom v-if="sortType == 0">
                    <template v-slot:activator="{ on }" v-if="sortType == 0">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 0" @click="sortType = 1; sortRegisters()">
                            <v-icon>mdi-sort-ascending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by ascending</span>
                </v-tooltip>

                <v-tooltip bottom v-if="sortType == 1">
                    <template v-slot:activator="{ on }" v-if="sortType == 1">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 1" @click="sortType = 0; sortRegisters()">
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by descending</span>
                </v-tooltip>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Filter Current View"
                        prepend-inner-icon="mdi mdi-filter"
                        outlined
                        single-line
                        @keyup="filteredSearch"
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>


        <div id="divHeaders" style="width: 100%; overflow-x: auto" class="sticky" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle incomingProjects">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-file-document-check-outline</v-icon>
                                <label class="pipelineWhiteColor">Incoming Projects</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdIncomingProjects }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                    style="display: inline-block; flex: none;"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle pending">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-list-status</v-icon>
                                <label class="pipelineWhiteColor">Permit File Review</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdPending }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle underReview">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-notebook-edit-outline</v-icon>
                                <label class="pipelineWhiteColor">Ready to Submit</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdUnderReview }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2  pipelineTitle local">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-check</v-icon>
                                <label class="pipelineWhiteColor">Permit in Review</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdOngoing }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2  pipelineTitle approved">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-file-check-outline</v-icon>
                                <label class="pipelineWhiteColor">Permit Approved and Issued</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdCompleted }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>

        </div>

        <div id="divContents" style="width: 100%; overflow-x: auto" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listIncomingProjectsFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="100" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text 
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="checkProjectHoldAndGetBackgroundColor(item)"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <v-divider />
                                <div class="marginInfoBottom" />

                                <label>Drafter Team</label>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamDrafter" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Permitting Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPermit" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Electric Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamElectric" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Plumbing Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPlumbing" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Gas Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamGas" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="checkProjectHoldAndGetBackgroundColor(item) + ' justify-center'"
                        >
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                    style="display: inline-block; flex: none;"
                >                    
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listPermitFileInReviewFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_PENDING, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'uploadDocuments', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-file-document-plus</v-icon> Upload Documents</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.datePermittingStep1Formatted }}
                                <span :style="item.datePermittingStep1DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.datePermittingStep1DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.datePermittingStep1DeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_PENDING, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_UNDER_REVIEW, item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <v-divider />
                                <div class="marginInfoBottom" />

                                <label>Drafter Team</label>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamDrafter" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Permitting Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPermit" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Electric Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamElectric" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Plumbing Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPlumbing" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Gas Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamGas" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_PENDING, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="item.datePermittingStep1DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listReadyToSubmitFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>

                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_UNDER_REVIEW, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'underReview', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-check-circle-outline</v-icon> Check</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.datePermittingStep2Formatted }}
                                <span :style="item.datePermittingStep2DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.datePermittingStep2DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.datePermittingStep2DeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_UNDER_REVIEW, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_UNDER_REVIEW, item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <v-divider />
                                <div class="marginInfoBottom" />

                                <label>Drafter Team</label>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamDrafter" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Permitting Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPermit" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Electric Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamElectric" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Plumbing Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPlumbing" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Gas Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamGas" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_UNDER_REVIEW, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="item.datePermittingStep2DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>

                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listPermitInReviewFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>

                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_ONGOING, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'ongoing', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-check-circle-outline</v-icon> Check</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.PERMITTING_READY_SUBMIT)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.returnCard == 1"
                                            @click="showDialogStatus(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to...</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.datePermittingStep3Formatted }}
                                <span :style="item.datePermittingStep3DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.datePermittingStep3DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.datePermittingStep3DeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_ONGOING, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_ONGOING, item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <v-divider />
                                <div class="marginInfoBottom" />

                                <label>Drafter Team</label>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamDrafter" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Permitting Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPermit" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Electric Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamElectric" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Plumbing Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPlumbing" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Gas Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamGas" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_ONGOING, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="item.datePermittingStep3DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.PERMITTING_ONGOING, item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <div class="marginInfoBottom" />

                                <label style="color: var(--color__red) !important;">Pending Permits</label>
                                <br />
                                <label 
                                    style="font-size: 10px; font-weight: normal !important; color: var(--color__red) !important"
                                    v-for="(item, index) in listPermitting" :key="index"
                                
                                >
                                    - <strong>{{ item.description }}</strong><br />
                                    <span style="margin-left: 15px;" v-for="(itemResp, indexResp) in item.listResponsibles" :key="indexResp">
                                        {{ itemResp.nameContractor }} <br />
                                    </span>
                                    <br />
                                </label>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listPermitApprovedAndIssuedFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>

                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'fileReview', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-file-document-plus</v-icon> Upload Documents</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.PERMITTING_PERMIT_REVIEW)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.datePermittingStep3Formatted }}
                                <span :style="item.datePermittingStep3DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.datePermittingStep3DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.datePermittingStep3DeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS, item)}`"
                        >
                            <v-col 
                                cols="12"
                            >
                                <v-divider />
                                <div class="marginInfoBottom" />

                                <label>Drafter Team</label>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamDrafter" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Permitting Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPermit" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Electric Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamElectric" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Plumbing Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamPlumbing" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>

                                <div class="teamTitle"><label>Gas Team</label></div>
                                <div class="techName" v-for="(itemTech, index) in item.listTeamGas" :key="index">
                                    - {{ getTechName(itemTech) }} <br />
                                </div>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>
                            
                            <v-col 
                                cols="2"
                                v-if="item.datePermittingStep4DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="var(--color__construction_dashboard_bbq)"
                                            left
                                            v-on="on"
                                            large
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>
            </v-row>        
        </div>        

		<v-dialog
			v-model="showDialogRevision"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    Revision
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <label>Select a permit</label>
                            <v-combobox
                                v-model="permitSelected"
                                :items="listPermit"
                                item-text="permitTitle"
                                item-value="id"
                                dense
                                clearable
                                outlined
                                @change="showPermitHistory()"
                            ></v-combobox>

						</v-col>
                        
					</v-row>

                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Upload new permit file</label>
                            <v-file-input
                                v-model="fileDocument" 
                                hint=" "
                                persistent-hint
                                accept=".*"
                                small-chips
                                outlined
                                dense
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                            ></v-file-input>
                        </v-col>
                    </v-row>

					<v-row v-if="permitSelected != null && permitSelected != undefined">
						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <label>History</label>
                            <v-simple-table
                                fixed-header
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                File Name
                                            </th>
                                            <th class="text-left">
                                                Created On
                                            </th>
                                            <th class="text-left">
                                                Created By
                                            </th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in listPermitFiles" :key="index"
                                        >
                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                            <td> 
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-if="index == 0" 
                                                            small 
                                                            color="green"
                                                            v-on="on"
                                                        >
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Latest File</span>
                                                </v-tooltip>
                                            </td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip 
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                        >
                                                            Download
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to Download [{{ item.fileName }}]</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
						</v-col>
                        
					</v-row>

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 10px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel buttonsHigher"
							outlined
							@click="showDialogRevision = false; permitSelected = null;"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 buttonsHigher"
							outlined
							@click="confirmRevision()"
						>
							Save
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
        
        <DialogHold 
            :id="dialogHoldResume.id"
            :type="dialogHoldResume.type"
            v-on:methodConfirmToCall="dialogHoldResume.methodConfirm"
            :showDialogHold.sync="dialogHoldResume.show"
        />
        
        <DialogStatus
            :id="dialogStatus.id"
            :listStatus="dialogStatus.listStatus"
            v-on:methodConfirmToCall="dialogStatus.methodConfirm"
            :showDialogStatus.sync="dialogStatus.show"
        />
        
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogHold from "@/components/Services/DialogHold";
    import DialogStatus from "@/components/Services/DialogStatus";
    import { filterValuePart, orderBy } from '@/utilities/Utils';
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        URL_ATTACHMENT, 
        PROJECT_STATUS,
        CATEGORY_TEAM,
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            DialogHold,
            DialogStatus
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

            filter: {
                fastSearch: ''
            },

            serviceId: 0,

            listIncomingProjects: [],
            listIncomingProjectsFiltered: [],
            qtdIncomingProjects: 0,

            listPermitFileInReview: [],
            listPermitFileInReviewFiltered: [],
            qtdPending: 0,

            listReadyToSubmit: [],
            listReadyToSubmitFiltered: [],
            qtdUnderReview: 0,

            listPermitInReview: [],
            listPermitInReviewFiltered: [],
            qtdOngoing: 0,

            listPermitApprovedAndIssued: [],
            listPermitApprovedAndIssuedFiltered: [],
            qtdCompleted: 0,

            showDialogRevision: false,
            permitSelected: null,
            fileDocument: null,
            listPermit: [],
            listPermitFiles: [],

            listPermitting: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            sortSelected: null,
            sortType: 0,
            listSortOptions: [
                { id: 1, description: 'Registration Date', listField: ['id'] },
                { id: 2, description: 'Deadline', listField: ['datePermittingStep1DeadlineDays', 'datePermittingStep2DeadlineDays', 'datePermittingStep3DeadlineDays', 'datePermittingStep4DeadlineDays'] },
                { id: 3, description: 'Responsible', listField: ['permitterUserName'] },
                { id: 4, description: 'Contract Amount', listField: ['contractValue'] }
            ],

            dialogHoldResume: {
                id: 0,
                type: "H",
                show: false,
                methodConfirm: ( () => {} ),
            },

            listStatus: [],
            dialogStatus: {
                id: 0,
                listStatus: [],
                show: false,
                methodConfirm: ( () => {} ),
            },

            PROJECT_STATUS
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            showImportantMessage(item) {
                return item.importantMessage != null && 
                       item.importantMessage != undefined && 
                       item.importantMessage.toString().trim() != ""
            },

            checkStatusCard(idStatusCard, itemProject) {

                if (itemProject != null && itemProject.status < itemProject.statusOriginal) {

                    if (itemProject.status == idStatusCard) {
                        return 'card-with-problem'
                    }
                    else {
                        return 'card-waiting-solve-problem'
                    }
                }
            },

            checkHoldSituation(item) {
                return item.situation == 2
            },

            checkProjectHoldAndGetBackgroundColor(item) {
                return this.checkHoldSituation(item) ? '': '';
            },

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: { id } });
            },
			
            getSituationColor (situation) {

                let color = "";

                switch (situation) {

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 2:
                        color = 'var(--color__red)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;
                }

                return color
            },
			
            getClassificationColor (classification) {

                let color = "";

                switch (classification) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__alert)';
                        break;

                    case 3:
                        color = 'var(--color__main)';
                        break;

                    case 4:
                        color = 'var(--color__blue)';
                        break;
                }

                return color
            },
            
            haveGateCode(item) {
                return item.customerAddressGateCode != null && item.customerAddressGateCode != undefined && item.customerAddressGateCode != ''
            },

            filteredSearch() {

                let filter = this.filter.fastSearch.toLowerCase();
                
                this.listIncomingProjectsFiltered = filterValuePart(this.listIncomingProjects, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listPermitFileInReviewFiltered = filterValuePart(this.listPermitFileInReview, filter, ['step', 'customerAddress', 'customerCity', 'datePermittingStep2Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted', 'permitterUserName']);
                this.listReadyToSubmitFiltered = filterValuePart(this.listReadyToSubmit, filter, ['step', 'customerAddress', 'customerCity', 'datePermittingStep3Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted', 'permitterUserName']);
                this.listPermitInReviewFiltered = filterValuePart(this.listPermitInReview, filter, ['step', 'customerAddress', 'customerCity', 'datePermittingStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted', 'permitterUserName']);
                this.listPermitApprovedAndIssuedFiltered = filterValuePart(this.listPermitApprovedAndIssued, filter, ['step', 'customerAddress', 'customerCity', 'datePermittingStep5Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted', 'permitterUserName']);

                return null;
            },

            sortRegisters() {

                let field = ['id'];

                if (this.sortSelected != null && this.sortSelected != undefined) {
                    field = this.sortSelected.listField;
                }

                if (field.length == 1) {
                    this.listIncomingProjectsFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listPermitFileInReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listReadyToSubmitFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listPermitInReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listPermitApprovedAndIssuedFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                }
                else {
                    this.listPermitFileInReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listReadyToSubmitFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[1]) });
                    this.listPermitInReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[2]) });
                    this.listPermitApprovedAndIssuedFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[3]) });
                }
            },

            async checkNotes(idServiceProject) {
                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService: 0, 
                    idServiceProject, 
                    onlyCheckExists: true, 
                    idStatus: 0, 
                    idPanel: 0, 
                    idStage: 0,
                    idUser: 0
                } );
                if (this.listNote.length > 0) return 1; else return 0;
            },

            async checkProductsIcons(idServiceProject) {
                return await this.$store.dispatch("productModule/ListProductsWithIconInProject", { idService: 0, idServiceProject });
            },

            async checkIconsAndTeams() {
                this.listIncomingProjectsFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    await this.assignTechs(item);

                    this.$forceUpdate();
                })

                this.listPermitFileInReviewFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    await this.assignTechs(item);

                    this.$forceUpdate();
                })
                
                this.listReadyToSubmitFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    await this.assignTechs(item);

                    this.$forceUpdate();
                })
                
                this.listPermitInReviewFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    await this.assignTechs(item);

                    this.$forceUpdate();
                })
                
                this.listPermitApprovedAndIssuedFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    await this.assignTechs(item);

                    this.$forceUpdate();
                })
            },

            async checkStatusOnGoing() {

                this.listPermitting = [];

                if (this.listPermitInReviewFiltered != null && this.listPermitInReviewFiltered != undefined && this.listPermitInReviewFiltered.length > 0) {

                    this.listPermitInReviewFiltered.forEach(async itemOnGoing => {

                        const responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", itemOnGoing.id);

                        const listPermittingRequest = responsePermitting.result.filter(per => per.apply == 1);

                        listPermittingRequest.forEach(itemPermitting => {

                            let listResponsibles = [];

                            if (itemPermitting.responsibleSelected != null && itemPermitting.responsibleSelected != undefined) {
                                itemPermitting.responsibleSelected.forEach(itemResp =>  {

                                    let nameContractor = '';

                                    if (itemResp.idResponsible == 3 && itemPermitting.showContractor == 1) {
                                        nameContractor = `${itemResp.responsibleDescription}: ${itemPermitting.nameContractor}`
                                    }
                                    else {
                                        nameContractor = `${itemResp.responsibleDescription}`
                                    }

                                    listResponsibles.push({ nameContractor });
                                })
                            }

                            if (itemPermitting.status == null || 
                                itemPermitting.status == undefined ||
                                itemPermitting.status != this.PROJECT_STATUS.SALES_IN_PROGRESS || 
                                itemPermitting.sentCity != 1) {

                                this.listPermitting.push({
                                    description: itemPermitting.permitTitle,
                                    listResponsibles
                                })
                            }
                            // else {
                            
                            //     this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: itemPermitting.idPermitting }).then (response => {

                            //         if (response != null && response != undefined && response.length > 0) {
                            //             response.forEach(itemFile => {                             
                                
                            //                 if (itemFile.id == 0 && (itemFile.typeDocument == TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value || itemFile.typeDocument == TYPE_DOCUMENT.PERMITTING_NOC.value) ) {

                            //                     this.listPermitting.push({
                            //                         description: itemPermitting.permitTitle,
                            //                         nameContractor
                            //                     })
                            //                 }

                            //                 this.$forceUpdate();
                            //             })
                            //         }
                            //         else {
                            //             this.listPermitting.push({
                            //                 description: itemPermitting.permitTitle,
                            //                 nameContractor
                            //             })
                            //         }
                            //     });
                            // }
                        })
                    })
                }
            },

            async getRegisters() {
                let response = await this.$store.dispatch("serviceModule/ListPipelinePermitting");
                this.sortSelected = this.listSortOptions[0];

                if (response != null && response != undefined) {

                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {

                        this.listIncomingProjects = [...response.listPipeline.filter(pip => pip.status >= this.PROJECT_STATUS.DRAFTING_REVIEW && pip.status <= this.PROJECT_STATUS.PERMITTING_INCOMING_PROJECTS)];
                        if (this.listIncomingProjects != null && this.listIncomingProjects != undefined) {
                            this.qtdIncomingProjects = this.listIncomingProjects.length;
                        }
                        this.listIncomingProjectsFiltered = [...this.listIncomingProjects];


                        this.listPermitFileInReview = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING)];
                        if (this.listPermitFileInReview != null && this.listPermitFileInReview != undefined) {
                            this.qtdPending = this.listPermitFileInReview.length;
                        }
                        this.listPermitFileInReviewFiltered = [...this.listPermitFileInReview];


                        this.listReadyToSubmit = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.PERMITTING_READY_SUBMIT)];
                        if (this.listReadyToSubmit != null && this.listReadyToSubmit != undefined) {
                            this.qtdUnderReview = this.listReadyToSubmit.length;
                        }
                        this.listReadyToSubmitFiltered = [...this.listReadyToSubmit];


                        this.listPermitInReview = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.PERMITTING_PERMIT_REVIEW)];
                        if (this.listPermitInReview != null && this.listPermitInReview != undefined) {
                            this.qtdOngoing = this.listPermitInReview.length;
                        }
                        this.listPermitInReviewFiltered = [...this.listPermitInReview];


                        this.listPermitApprovedAndIssued = [...response.listPipeline.filter(pip => pip.status >= this.PROJECT_STATUS.PERMITTING_FILE_REVIEW)];
                        if (this.listPermitApprovedAndIssued != null && this.listPermitApprovedAndIssued != undefined) {
                            this.qtdCompleted = this.listPermitApprovedAndIssued.length;
                        }
                        this.listPermitApprovedAndIssuedFiltered = [...this.listPermitApprovedAndIssued];

                        await this.checkIconsAndTeams();
                        await this.checkStatusOnGoing();
                    }
                }
            },

            async backPreviousStep(id, status) {
                
                const updateStatusRequest = {
                    id,
                    status
                };

                const result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    this.getRegisters();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            async showRevision(item) {

                this.showLoading();

                this.fileDocument = null;

                let responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", item.id);

                this.listPermit = responsePermitting.result.filter(per => per.apply == 1 && per.permitUploadDocument == 1);

                this.hideLoading();

                this.showDialogRevision = true;
            },

            async showPermitHistory() {

                this.listPermitFiles = [];

                if (this.permitSelected != null && this.permitSelected != undefined) {

                    //PERMIT HISTORY
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                        typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, 
                        typeDocument: TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value, 
                        idKey: this.permitSelected.id
                    });

                    if (listAttachments != null && listAttachments != undefined) {

                        listAttachments.forEach(itemProposal => {
                            this.listPermitFiles.push ({
                                id: itemProposal.id,
                                fileName: itemProposal.fileName,
                                fileType: itemProposal.fileType,
                                createdOn: itemProposal.dateRegisterFormatted,
                                userName: itemProposal.userNameRegister
                            });
                        });

                        this.listPermitFiles.sort(this.descById);
                    }
                }
            },

            async uploadFile(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async confirmRevision() {

                if (this.permitSelected != null && this.permitSelected != undefined && this.fileDocument != null && this.fileDocument != undefined) {
                    
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `You will upload a new file [${this.fileDocument.name}] to this permit [${this.permitSelected.permitTitle}], confirm your decision?`,
                        methodConfirm: this.saveRevision,
                        params: 0
                    };
                }
            },

            async saveRevision() {

                if (this.permitSelected != null && this.permitSelected != undefined) {
                    this.uploadFile(0, this.permitSelected.id, this.fileDocument, TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value);
                    this.showToast("success", "Success!", "File uploaded with successfully!");
                    this.showDialogRevision = false;
                    this.permitSelected = null;
                    this.fileDocument = null;
                }
            },

            async showDialogHold(id, type) {
                this.dialogHoldResume = {
                    id,
                    show: true,
                    type,
                    methodConfirm: ( () => {  this.getRegisters(); })
                }
            },

            async getLists() {
                let listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 3 && sts.id <= 12 && [4].includes(sts.id) == false).sort((a, b) => { return orderBy(a, b, 0, 'id') });
            },

            showDialogStatus(id) {
                this.dialogStatus = {
                    id,
                    listStatus: this.listStatus,
                    show: true,
                    methodConfirm: ( () => {  this.getRegisters(); })
                }
            },

            async assignTechs(item) {
                
                const listTeam = await this.$store.dispatch("serviceModule/GetTeam", item.id);

                const listTeamDrafter = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.DRAFTER.value);
                const listTeamPermit = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.PERMITTECH.value);
                const listTeamElectric = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.ELECTRICTECH.value);
                const listTeamPlumbing = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.PLUMBINGTECH.value);
                const listTeamGas = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.GASTECH.value);

                const userName = 'Not yet assigned';

                if (listTeamDrafter != null && listTeamDrafter.length > 0) {
                    item.listTeamDrafter = listTeamDrafter;
                }
                else {
                    item.listTeamDrafter = [{ userName }]
                }

                if (listTeamPermit != null && listTeamPermit.length > 0) {
                    item.listTeamPermit = listTeamPermit;
                }
                else {
                    item.listTeamPermit = [{ userName }]
                }

                if (listTeamElectric != null && listTeamElectric.length > 0) {
                    item.listTeamElectric = listTeamElectric;
                }
                else {
                    item.listTeamElectric = [{ userName }]
                }

                if (listTeamPlumbing != null && listTeamPlumbing.length > 0) {
                    item.listTeamPlumbing = listTeamPlumbing;
                }
                else {
                    item.listTeamPlumbing = [{ userName }]
                }

                if (listTeamGas != null && listTeamGas.length > 0) {
                    item.listTeamGas = listTeamGas;
                }
                else {
                    item.listTeamGas = [{ userName }]
                }
            },

            getTechName(itemTech) {
                if (itemTech.userName != null && itemTech.userName != '') {
                    return itemTech.userName;
                }
                else if (itemTech.nameOther != null && itemTech.nameOther != '') {
                    return itemTech.nameOther;
                }
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.getLists();
            this.hideLoading();
        },        
    })
</script>

<style scoped>

    .v-card__actions {
        padding: 0px;
    }

    .rowSameWidth {
        display: grid; 
        grid-template-columns: repeat(auto-fit,minmax(64px,auto));
    }

    .pending {
        background-color: var(--color__pipeline_pending);
    }

    .underReview {
        background-color: var(--color__pipeline_under_review)
    }

    .local {
        background-color: var(--color__pipeline_local)
    }

    .approved {
        background-color: var(--color__pipeline_approved);
    }

    .margin-top {
        margin-top: 0px;
    }

    .teamTitle {
        margin-top: 5px;
    }

    .techName {
        margin-left: 10px; 
        font-size: 11px; 
        color: gray !important;
    }
</style>